import Link from 'next/link';
import { useRouter } from 'next/router';
import {
  useRef,
  useEffect,
  useState,
  MouseEventHandler,
} from 'react';
import { RemoveScroll } from 'react-remove-scroll';
import { useAnimation } from 'framer-motion';
import { Drawer, Overlay, Icons, Button } from '@studenten/ui-components';

import { APP_ROUTES } from 'constants/routes';
import { useOutsideClick } from 'features/layout/hooks/useOutsideClick';
import { isMenuLink, MenuItem, normalizeHeaderMenuItems } from 'utils/helpers';

import {
  Container,
  Categories,
  SubCategories,
  Header,
  AuthButtonsContainer,
  BackButtonContainer,
  ListContainer,
  StyledDivider,
} from './MobileMenu.styled';
import type { Props } from './types';


export const MobileMenu = ({ isOpen, setOpen, headerMenuItems }: Props) => {
  const router = useRouter();
  const [menuStep, setMenuStep] = useState<number>(0);
  const [activeKey, setActiveKey] = useState<{ category?: string; parent?: string } | null>(null);
  const drawerRef = useRef<HTMLDivElement | null>(null);
  const subCategoriesControls = useAnimation();

  const normalized = normalizeHeaderMenuItems(headerMenuItems);
  const parentLevel = normalized.filter((item) => {
    if (activeKey) {
      return item.level === menuStep - 1;
    }

    return item.level === menuStep;
  });

  const subCategories = normalized.filter((item) => {
    return item.level === menuStep && !!activeKey && item.parent === activeKey.category;
  });

  useEffect(() => {
    subCategoriesControls.start({
      transition: { duration: 0.25 },
      x: menuStep === 0 ? 278 : 0,
    });
  }, [menuStep]);

  const handleCloseMenu = () => {
    setOpen(false);
    setMenuStep(0);
    setActiveKey(null);
  };

  const handleClickBack: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();

    let nextStep = 0;
    setMenuStep((prev) => {
      nextStep = Math.max(prev - 1, 0);

      return nextStep;
    });
    setActiveKey((prevState) => {
      if (nextStep === 0 || !prevState || !prevState?.parent) {
        return null;
      }

      return { category: prevState.parent };
    });
  };

  const renderMenuItem = (menuItem: MenuItem) => {
    const { label, level } = menuItem;
    if (isMenuLink(menuItem)) {
      return (
        <li key={label}>
          <Link href={menuItem.href || ''} prefetch={false}>
            <a
              onClick={(e) => {
                e.preventDefault();
                void router.push(menuItem.href);
                handleCloseMenu();
              }}
            >
              <span>{menuItem.label}</span>
            </a>
          </Link>
        </li>
      );
    }

    const { category, parent } = menuItem;
    const onClick: MouseEventHandler<HTMLDivElement> = (e) => {
      e.preventDefault();
      setMenuStep(level + 1);
      setActiveKey({ category, parent });
    };

    return (
      <li key={label}>
        <div onClick={onClick}>
          <span>{label}</span>
          {category && <Icons.RightArrow />}
        </div>
      </li>
    );
  };

  useOutsideClick(drawerRef, handleCloseMenu);
  return (
    <RemoveScroll enabled={isOpen}>
      <Drawer isOpen={isOpen} ref={drawerRef}>
        <Container>
          <Categories>
            <Header>
              <Button onClick={handleCloseMenu}>
                <Icons.Cross size="large" />
              </Button>
              <AuthButtonsContainer>
                <Link href={APP_ROUTES.PARTNERS_LOGIN} passHref prefetch={false}>
                  <Button variant="base" as="a">
                    Inloggen
                  </Button>
                </Link>
                <Link href={APP_ROUTES.PARTNERS_SIGN_UP} passHref prefetch={false}>
                  <Button variant="base" as="a">
                    Inhuren
                  </Button>
                </Link>
              </AuthButtonsContainer>
            </Header>
            <StyledDivider />
            <ListContainer>
              <ul>{parentLevel.map((menuItem) => renderMenuItem(menuItem))}</ul>
            </ListContainer>
          </Categories>
          <SubCategories animate={subCategoriesControls}>
            <Header>
              <BackButtonContainer>
                <Button onClick={handleClickBack}>
                  <Icons.LeftArrow />
                  Back
                </Button>
              </BackButtonContainer>
            </Header>
            <StyledDivider />
            <ListContainer>
              <ul>{subCategories.map((item) => renderMenuItem(item))}</ul>
            </ListContainer>
          </SubCategories>
        </Container>
      </Drawer>
      <Overlay isOpen={isOpen} />
    </RemoveScroll>
  );
};
